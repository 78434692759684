import svgInImg from "../assets/in.png";

import HumedadImg from "../assets/humedad.png";
import TempImg from "../assets/temp.png";
import SoilImg from "../assets/soil.png";
import SwitchImg from "../assets/switch.png";
import svgOutImg from "../assets/out.png";
import ClockImg from "../assets/clock.png";

import PhSensorImg from "../assets/phSensor.png";
import CeSensorImg from "../assets/ceSensor.png";
import PumbImg from "../assets/pumb.png";
import WTempImg from "../assets/WTemp.png";

import svgNodeImg from "../assets/node.png";
import AmbienteImg from "../assets/Ambiente.png";
import AguaImg from "../assets/Agua.png";

const backGroundList = "#616161";

const IN_HUMEDAD = "0";
const IN_TEMPERATURA = "1";
const IN_SUSTRATO = "2";
const IN_SWITCH = "3";
const OUT_AC = "5";
const IN_RELOJ = "D";
const OUT_PUMB_DC = "6";
const IN_PH = "7";
const IN_CE = "8";
const IN_TEMP_AGUA = "9";
const IN_TEMP_HOJA = "10";
const OUT_PWM = "11";
const IN_PAR = "12";
const IN_DPV = "13";
const IN_DLI = "14";
const IN_CO2 = "15"; //Co2
const IN_ORP_OD = "16";
//16 ORP/Oxigeno disuelto

//Node V2 Values
const CONDUCTIVITY = "0";
const TEMPERATURE = "1";
const PH = "2";
const HUMIDITY = "3";
const PRESSURE = "4";
const CO2 = "5";
const NITROGEN = "6";
const PHOSPHORUS = "7";
const POTASSIUM = "8";
const PAR = "9";
const WINDSPEED = "10";
const RAINGAUGE = "11";
//Nodos V1
const TEMP_AMB = "50";
const HUM_AMB = "51";
const CO2_AMB = "52";
const HUM_SOIL = "53";
const TEMP_SOIL = "54";
const EC_SOIL = "55";
const PH_SOIL = "56";


const TYPE_MODULE_AMBIENTE = "1";
const TYPE_MODULE_AGUA = "2";

const MODULE_TYPE_MASTER = "0";
const MODULE_TYPE_AMBIENTE = "1";
const MODULE_TYPE_AGUA = "2";

const VIDEO_ITEM = "video";
const REMINDER_ITEM = "reminder";

const MILLI_SECONDS = 1000;
export const TIME_TO_ASK_ESP32 = 60 * MILLI_SECONDS;

const ALL_KINDS = {
  IN_HUMEDAD,
  IN_TEMPERATURA,
  IN_SUSTRATO,
  IN_SWITCH,
  OUT_AC,
  IN_RELOJ,
  OUT_PUMB_DC,
  IN_PH,
  IN_CE,
  IN_TEMP_AGUA,
  IN_TEMP_HOJA,
  OUT_PWM,
  IN_PAR,
  IN_DPV,
  IN_DLI,
  IN_CO2,
  IN_ORP_OD
};

export const continuosSensors = [
  IN_HUMEDAD,
  IN_TEMPERATURA,
  IN_SUSTRATO,
  IN_PH,
  IN_CE,
  IN_TEMP_AGUA,
  IN_TEMP_HOJA,
  IN_PAR,
  IN_DPV,
  IN_DLI,
  IN_CO2,
  IN_ORP_OD
];

export const discreteSensors = [IN_SWITCH];
export const discreteOuts = [OUT_AC, OUT_PUMB_DC];
export const TYPES_OF_DEVICES = {
  MODULE_TYPE_MASTER,
  MODULE_TYPE_AMBIENTE,
  MODULE_TYPE_AGUA,
};
export const TOGGLE_POS_AUTO = "2";

const svgKindIO = (kind) => {
  let svg = {
    shape: "image",
    href: svgInImg,
    type: "Entrada",
    unit: "",
  };

  if (kind === IN_HUMEDAD) {
    svg.href = HumedadImg;
    svg.unit = "%";
    svg.type = "S. de Humedad";
  } else if (kind === IN_TEMPERATURA) {
    svg.href = TempImg;
    svg.unit = "°C";
    svg.type = "S. de Temperatura";
  } else if (kind === IN_SUSTRATO) {
    svg.href = SoilImg;
    svg.unit = "%";
    svg.type = "S. de Humedad Tierra";
  } else if (kind === IN_SWITCH) {
    svg.href = SwitchImg;
    svg.unit = "";
    svg.type = "S. de contacto seco";
  } else if (kind === OUT_AC) {
    svg.href = svgOutImg;
    svg.unit = "";
    svg.type = "Salida C.A";
  } else if (kind === IN_RELOJ) {
    svg.href = ClockImg;
    svg.unit = "";
    svg.type = "Reloj";
  } else if (kind === OUT_PUMB_DC) {
    svg.href = PumbImg;
    svg.unit = "";
    svg.type = "Bomba Dosificadora";
  } else if (kind === IN_PH) {
    svg.href = PhSensorImg;
    svg.unit = "";
    svg.type = "S. de PH";
  } else if (kind === IN_CE) {
    svg.href = CeSensorImg;
    svg.unit = "mS/cm";
    svg.type = "S. de CE";
  } else if (kind === IN_TEMP_AGUA) {
    svg.href = WTempImg;
    svg.unit = "°C";
    svg.type = "S. de Temperatura de Agua";
  } else if (kind === IN_TEMP_HOJA) {
    svg.href = TempImg;
    svg.unit = "°C";
    svg.type = "S. de Temperatura de la Hoja";
  } else if (kind === OUT_PWM) {
    svg.href = svgOutImg;
    svg.unit = "%";
    svg.type = "Salida DC variable";
  } else if (kind === IN_PAR) {
    svg.href = PhSensorImg;
    svg.unit = "µmol/m²s";
    svg.type = "S. PAR (Luminosidad)";
  } else if (kind === IN_DPV) {
    svg.href = PhSensorImg;
    svg.unit = "KPa";
    svg.type = "S. DPV";
  } else if (kind === IN_DLI) {
    svg.href = PhSensorImg;
    svg.unit = "u";
    svg.type = "S. DLI";
  }

  return svg;
};

const svgKindNode = (kind,canId) => {
  let svg = {
    shape: "image",
    href: svgInImg,
    type: "Entrada",
    unit: "",
  };

  if(canId === "0"){
    if (kind === TEMP_AMB) {
      svg.href = TempImg;
      svg.unit = "°C";
      svg.type = "S. de Temperatura ambiental";
    } else if (kind === HUM_AMB) { 
      svg.href = HumedadImg;
      svg.unit = "%";
      svg.type = "S. de Humedad ambiental";
    } else if (kind === CO2_AMB) { 
      svg.href = AmbienteImg;
      svg.unit = "ppm";
      svg.type = "S. de CO2 ambiental";
    } else if (kind === TEMP_SOIL) { 
      svg.href = TempImg;
      svg.unit = "°C";
      svg.type = "S. de Temperatura del Soil";
    } else if (kind === HUM_SOIL) { 
      svg.href = HumedadImg;
      svg.unit = "%";
      svg.type = "S. de Humedad del Soil";
    } else if (kind === EC_SOIL) { 
      svg.href = CeSensorImg;
      svg.unit = "uS/cm";
      svg.type = "S. de EC del Soil";
    } else if (kind === PH_SOIL) { 
      svg.href = PhSensorImg;
      svg.unit = "";
      svg.type = "S. de pH del Soil";
    }
  } else {
    if (kind === CONDUCTIVITY) {
      svg.href = CeSensorImg;
      svg.unit = "uS/cm";
      svg.type = "S. de EC";
    } else if (kind === TEMPERATURE) { 
      svg.href = TempImg;
      svg.unit = "°C";
      svg.type = "S. de Temperatura";
    } else if (kind === PH) { 
      svg.href = PhSensorImg;
      svg.unit = "";
      svg.type = "S. de pH";
    } else if (kind === HUMIDITY) { 
      svg.href = HumedadImg;
      svg.unit = "%";
      svg.type = "S. de Humedad";
    } else if (kind === PRESSURE) {
      svg.href = AmbienteImg;
      svg.unit = "atm";
      svg.type = "Presión atmosférica";
    } else if (kind === CO2) { 
      svg.href = AmbienteImg;
      svg.unit = "ppm";
      svg.type = "S. de CO2";
    } else if (kind === NITROGEN) { 
      svg.href = svgInImg;
      svg.unit = "ppm";
      svg.type = "S. de Nitrógeno";
    } else if (kind === PHOSPHORUS) { 
      svg.href = svgInImg ;
      svg.unit = "ppm";
      svg.type = "S. de Fósforo";
    } else if (kind === POTASSIUM) {
      svg.href = svgInImg ;
      svg.unit = "ppm";
      svg.type = "S. de Potasio";
    } else if (kind === PAR) {
      svg.href = svgInImg;
      svg.unit = "µmol/m²s";
      svg.type = "S. PAR (Luminosidad)";
    } else if (kind === WINDSPEED) {
      svg.href = AmbienteImg;
      svg.unit = "m/s";
      svg.type = "Velocidad del viento";
    } else if (kind === RAINGAUGE) {
      svg.href = AmbienteImg;
      svg.unit = "mm";
      svg.type = "Pluviómetro";
    } 
  }

  return svg;
};

const svgTypeModule = (type) => {
  let svg = {
    shape: "image",
    href: svgNodeImg,
    type: "Modulo",
  };

  if (type === TYPE_MODULE_AMBIENTE) {
    svg.href = AmbienteImg;
    svg.type = "Módulo de ambiente";
  } else if (type === TYPE_MODULE_AGUA) {
    svg.href = AguaImg;
    svg.type = "Módulo de agua";
  }

  return svg;
};

/** Cambiar el lenguaje de las opciones de la gráfica*/
const HIGHCHART_OPT_SPANISH = {
  lang: {
    loading: "Cargando...",
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    weekdays: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    shortMonths: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    exportButtonTitle: "Exportar",
    printButtonTitle: "Importar",
    rangeSelectorFrom: "Desde",
    rangeSelectorTo: "Hasta",
    rangeSelectorZoom: "Período",
    downloadPNG: "Descargar imagen PNG",
    downloadJPEG: "Descargar imagen JPEG",
    downloadPDF: "Descargar imagen PDF",
    downloadSVG: "Descargar imagen SVG",
    printChart: "Imprimir",
    resetZoom: "Reiniciar zoom",
    resetZoomTitle: "Reiniciar zoom",
    thousandsSep: ",",
    decimalPoint: ".",
  },
};


  const sensorsLorawan = [
    {
      id: "1",
      parameters: [
        {
          name: "Temp.Soil",
          outId: "1" 
        },
        {
          name: "Hum.Soil",
          outId: "3" 
        },
        {
          name: "EC",
          outId: "0" 
        }
      ]
    },
    {
      id: "2",
      parameters: [
        {
          name: "Temp.Soil",
          outId: "1" 
        },
        {
          name: "Hum.Soil",
          outId: "3"
        },
        {
          name: "EC",
          outId: "0" 
        },
        {
          name: "pH",
          outId: "2" 
        }
      ]
    },
    {
      id: "3",
      parameters: [
        {
          name: "Temp.Soil",
          outId: "1"
        },
        {
          name: "Hum.Soil",
          outId: "3"
        },
        {
          name: "EC",
          outId: "0" 
        },
        {
          name: "pH",
          outId: "2" 
        },
        {
          name: "Nitrogen",
          outId: "6" 
        },
        {
          name: "Phosphorus",
          outId: "7" 
        },
        {
          name: "Potassium",
          outId: "8" 
        }
      ]
    },
    {
      id: "4",
      parameters: [
        {
          name: "Temp.Amb.",
          outId: "1" 
        },
        {
          name: "Hum.Amb.",
          outId: "3" 
        }
      ]
    },
    {
      id: "5",
      parameters: [
        {
          name: "Temp.Amb.",
          outId: "1" 
        },
        {
          name: "Hum.Amb.",
          outId: "3"
        }
      ]
    },
    {
      id: "6",
      parameters: [
        {
          name: "PAR",
          outId: "9"
        }
      ]
    },
    {
      id: "7",
      parameters: [
        {
          name: "Vel.Viento",
          outId: "10"
        }
      ]
    },
    {
      id: "8",
      parameters: [
        {
          name: "Pluviómetro",
          outId: "11"
        }
      ]
    },
    {
      id: "9",
      parameters: [
        {
          name: "Hum.Amb.",
          outId: "3"
        },
        {
          name: "Temp.Amb.",
          outId: "1"
        },
        {
          name: "CO2",
          outId: "5"
        }
      ]
    },
    {
      id: "MS1",
      parameters: [
        {
          name: "Hum.Amb.",
          outId: "3"
        },
        {
          name: "Temp.Amb.",
          outId: "1"
        }
      ]
    },
    {
      id: "MS2",
      parameters: [
        {
          name: "CO2",
          outId: "5"
        },
        {
          name: "Hum.Amb.",
          outId: "3"
        },
        {
          name: "Presión",
          outId: "4"
        },
        {
          name: "Temp.Amb.",
          outId: "1"
        }
      ]
    }
];

export {
  backGroundList,
  svgKindIO,
  svgKindNode,
  svgTypeModule,
  HIGHCHART_OPT_SPANISH,
  VIDEO_ITEM,
  REMINDER_ITEM,
  ALL_KINDS,
  TYPE_MODULE_AMBIENTE,
  TYPE_MODULE_AGUA,
  sensorsLorawan
};
