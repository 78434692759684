import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardContent, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { TimeZone } from './TimeZone/TimeZone';
import { UserContext } from '../../../context/UserProvider';
import { ConfirmationPopup } from './ConfirmationPopup';
import { NotificationsSettings } from './Notifications/NotificationsSettings';
import { Save } from '@material-ui/icons';
import { db } from '../../../config/firebase';
import { ConductivityUnits } from './MeasurementUnits/ConductivityUnits';
import { EcUnits } from '../../../constants/UnitsConst';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	root: {
	  width: '100%',
	  padding: theme.spacing(2),
	  boxSizing: 'border-box',
	  boxShadow: "0px 0px 4px black", //offset-x | offset-y | blur-radius | color
	  [theme.breakpoints.down('xs')]: {
		width: '85vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	title: {
	  fontWeight: 'bold',
	},
	form: {
	  marginTop: theme.spacing(2),
	},
	button: {
	  marginTop: theme.spacing(2),
	},
  }));

export const ProfileSettings = () => {
	const classes = useStyles();
	const { userTimezone,saveTimeZone, usuario,unitForEC,setUnitForEC } = useContext(UserContext)
	const [selectedTimezone, setSelectedTimezone] = useState(userTimezone);
	const [popupIsOpen, setPopupIsOpen] = useState(false);
	const [timezoneChangeFlag, setTimezoneChangeFlag] = useState(false);
	const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([]);
	const [emailForNotification, setEmailForNotification] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [notificationChangeFlag, setNotificationChangeFlag] = useState(false);
	const [ecUnitsChangeFlag, setEcUnitsChangeFlag] = useState(false)
	const [selectedEcUnits, setSelectedEcUnits] = useState(unitForEC)
	const [isSaving, setIsSaving] = useState(false);
	const [alertIsOpen, setAlertIsOpen] = useState(false)

	const handleChangeSettings = () => {
		setIsSaving(true)
		setPopupIsOpen(false);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setAlertIsOpen(false)
	};

	useEffect(() => {
		const updateSettings = async () => {
			try {
				if(timezoneChangeFlag){
					const reply = await saveTimeZone(selectedTimezone)
					if(reply === "saved") {
						setIsSaving(false)
					}
				}  
				if(notificationChangeFlag){
					const docRef = db.collection(usuario.username).doc('notifications');
					const dataToSave = {
						emailAddress: emailForNotification,
						notificationMethods: [...selectedNotificationTypes]
					}
					await docRef.set({...dataToSave})
				}
				if(ecUnitsChangeFlag){
					const userDoc = db.collection("usuarios").doc(usuario.username);
					const unitSelected = EcUnits.find((element) => element.value === selectedEcUnits)
					setUnitForEC(unitSelected.value)
					const ecUnits = {
						divider: unitSelected.value,
						label: unitSelected.label
					}
					await userDoc.update({ ecUnits })
				}
				setIsSaving(false)
			} catch (error) {
				console.error("Error en el guardado de los ajustes:",error)
			}
		}

		if(isSaving){
			updateSettings();
		}
	}, [isSaving])

	useEffect(() => {
	  if (isSaving) {
		setAlertIsOpen(true)
	  }
	}, [isSaving])
	

	useEffect(() => {
	  const getNotificationsData = async () => {
		try {
			const docRef = db.collection(usuario.username).doc('notifications');
			const getData = await docRef.get();
			if(getData.exists){
				const userNotifications = getData.data().notificationMethods;
				const userEmail = getData.data().emailAddress;
				setSelectedNotificationTypes([...userNotifications]);
				setEmailForNotification(userEmail);
			}
		} catch (error) {
			console.error("Error en obtener datos de notificaciones:",error);
		}
	  }
	  
	  if(usuario.username){
		getNotificationsData();
	  }	  
	}, [usuario.username]);
	
	

  return (
	<div>
		<Card className={classes.root}>
        	<CardContent>
				<Typography variant="h6" className={classes.title}>
					Cuenta
				</Typography>
				{/* Formulario de la zona horaria */}
				<TimeZone 
				selectedTimezone={selectedTimezone} 
				newTimezone={setSelectedTimezone} 
				timezoneChangeFlag={() => setTimezoneChangeFlag(true)} />

				<div style={{ marginBottom: "15px", marginTop: "10px"}}>
					<Divider />
				</div>

				<Typography variant="h6" className={classes.title}>
					Notificaciones
				</Typography>

				<NotificationsSettings 
				selectedNotifications={selectedNotificationTypes}
				changeNotificationSelection={setSelectedNotificationTypes}
				emailUser={emailForNotification}
				setNewEmail={setEmailForNotification}
				changeNotificationFlag={setNotificationChangeFlag}
				invalidEmail={emailError}
				newStateInvalidEmail={setEmailError}
				/>

				<div style={{ marginBottom: "15px", marginTop: "10px"}}>
					<Divider />
				</div>

				<Typography variant="h6" className={classes.title}>
					Unidades de Medición para Sensores
				</Typography>

				<ConductivityUnits 
				selectedUnit={selectedEcUnits} 
				newUnitsForEC={setSelectedEcUnits}
				ecChangeUnitsFlag={() => setEcUnitsChangeFlag(true)} 
				/>

        	</CardContent>
      	</Card>

		<ConfirmationPopup 
		popupIsOpen={popupIsOpen} 
		handleClosePopup={() => setPopupIsOpen(false)} 
		handleChangeSettings={handleChangeSettings} />

		<Button
			variant="contained"
			color="primary"
			fullWidth
			className={classes.button}
			onClick={() => setPopupIsOpen(true)}
			startIcon={<Save />}
			disabled={isSaving || !(timezoneChangeFlag || notificationChangeFlag || ecUnitsChangeFlag) || emailError}
		>
			Guardar
		</Button>
		<Snackbar open={alertIsOpen} autoHideDuration={6000} onClose={handleClose}>
			<Alert onClose={handleClose} severity="success">
				¡Los cambios realizados en los ajustes generales se han actualizado y guardado exitosamente!
			</Alert>
		</Snackbar>
	</div>
  )
}
