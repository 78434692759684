import React, { useContext, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { LISTAS_DATA } from "../../../constants/routerConst";
import { isMobile } from "react-device-detect";
import { UserContext } from "../../../context/UserProvider";

const Listas = (props) => {

  const {dataMacCan,usuario}=useContext(UserContext);

  const [itemsForUser, setItemsForUser] = useState(LISTAS_DATA);

  useEffect(() => {
    const obtenerDatos=async()=>{
      let newArray = [];
      try{
        
        if(dataMacCan.length>0){

          await Promise.all(
          dataMacCan.map(async(itemMac)=>{

                await Promise.all(
                 itemMac.cans.map( async(itemCan)=>{
                   const InfoUser=usuario.username+'/infoDevices/'+itemMac.mac+'/'+itemCan.id+'/'+itemCan.type
                   const arrayInfo = InfoUser.split("/");
                   newArray = [...newArray, arrayInfo[4]]; 
                   
                  })
                ) 
          })
          )
        }
        const isAmbientModule = newArray.some(type => type === '1');
        if (!isAmbientModule) {
          const filterList = LISTAS_DATA.filter(item => item.text !== "Rutina de Riego");
          if(usuario.username !== "DanielTorres" && usuario.username !== "CultivoExteriorGapy") {
            const secondFilterList = filterList.filter(item => item.text !== "Bitácora");
            setItemsForUser(secondFilterList)
          } else {
            setItemsForUser(filterList);
          }
          
        } else {
          if(usuario.username !== "DanielTorres" && usuario.username !== "CultivoExteriorGapy") {
            const firstFilterList = LISTAS_DATA.filter(item => item.text !== "Bitácora");
            setItemsForUser(firstFilterList)
          } else {
            setItemsForUser(LISTAS_DATA);
          }
          
        }
                   
      
      }catch(error){
        console.log(error)
      }
    }
    obtenerDatos()
  }, [usuario.username,dataMacCan])

  const ListItems = ({ ComponentIcon, path, text }) => {
    if (isMobile) {
      // if (text !== "Sistema" && text !== "Gráficas" ) {
      if (text !== "Sistema" ) {
        return (
          <ListItem button onClick={() => props.history.push(path)}>
            <ListItemIcon>
              <ComponentIcon fontSize="large" />
            </ListItemIcon>

            <ListItemText primary={text} />
          </ListItem>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <ListItem button onClick={() => props.history.push(path)}>
          <ListItemIcon>
            <ComponentIcon fontSize="large" />
          </ListItemIcon>

          <ListItemText primary={text} />
        </ListItem>
      );
    }
  };

  return (
    <div>
      <List component="nav">
        {itemsForUser.map((item, i) => (
          <ListItems
            ComponentIcon={item.ComponentIcon}
            path={item.path}
            text={item.text}
            key={`Listas_${i}`}
          />
        ))}

        <Divider />
      </List>
    </div>
  );
};

export default withRouter(Listas);
