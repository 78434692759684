import React, { useEffect } from "react";
import { db } from "../../../../config/firebase";
import moment from "moment";
import "moment/locale/es";
import { UserContext } from "../../../../context/UserProvider";
import UpdatingButton from "./ConfigComponents/UpdatingButton";
import DialogConfirm from "./ConfigComponents/DialogConfirm";
import { backGroundList } from "../../../../constants/globalConst";

import Adjust from "@material-ui/icons/Adjust";
import { Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import CalibrationDialogForm from "./Calibration/CalibrationDialogForm";
import TransitionAlerts from "../../../Common/TransitionAlerts";

const selecOptionsPH = [
  { value: 2, name: "Reset PH" },
  { value: 0, name: "Calibración PH 4.0" },
  { value: 1, name: "Calibración PH 7.0" },
];

const infoStepperPH = [
  {
    tittleStep: "Preparación del sensor",
    text: ` `,
    textStep: [
      {
        main: "Consideraciones antes de calibrar la sonda",
        points: `Antes de calibrar la sonda de pH, asegúrese de que el instrumento esté encendido y conectado a la sonda.
         Limpie la punta de la sonda con agua destilada y séquela con un paño suave.`,
      },
      {
        main: "Soluciones de calibración de la sonda",
        points:
          "Asegúrese de contar con las soluciones de calibración para pH4.0 y pH7.0",
      },
    ],
  },
  {
    tittleStep: "Calibración",
    text: ` `,
    textStep: [
      {
        main: "Acerca del proceso de calibración",
        points: `El proceso de calibración es un paso importante para el funcionamiento correcto de su dispositivo, es un 
        proceso que le tomara unos minutos pero solo se realiza una vez.`,
      },
    ],
    //labelSlider:["Encender por 'x' seg.","Encender hasta obtener 'x' mlts.","Calibrar con probeta de 'x' mlts."]
  },
  {
    tittleStep: "Reseteo de valores",
    text: "Procesando",
    textStep: [
      {
        main: "Reseteando valores",
        points: `En su módulo de agua se observara el encendido de un indicador cuando este se apague el proceso quedará terminado.`,
      },
      {
        note: "Si necesita solo resetear los valores por defecto puede dar en finalizar",
      },
    ],
  },
  {
    tittleStep: "Solución pH4.0",
    text: " ",
    headers: [
      "Tenga en cuenta los siguientes consejos",
      "Tenga presente las siguientes indicaciones antes de comenzar",
      "Tenga paciencia el proceso puede demorar unos minutos",
      " ",
    ],
    textStep: [
      {
        main: "Solución de calibración",
        points: `La solución es crucial para obtener lecturas adecuadas por ello es importante que su solución se encuentre en el valor 
        correcto. Recomendamos adquirir soluciones recientes o verificar su valor con un medidor manual de pH.`,
      },
      {
        main: "Sonda del sensor",
        points: `Recuerde que al cambiar de solución de calibración es necesario tener limpia la sonda, cambiar de solución sin limpieza 
        previa provocara errores en las mediciones de su dispositivo.`,
      },
      {
        main: "Burbujas alrededor de la sonda",
        points: `Si observa que se forman burbujas alrededor del bulbo despues de sumergir en la solución agite levemente 
        la sonda para que desaparezcan.`,
      },
      {
        main: "Listo para la calibración",
        point_1: `Llene un recipiente limpio con la `,
        point_2: "solución de calibración de pH 4.0 ",
        point_3: `y sumerja la sonda en ella. Asegúrese de que el bulbo(extremo sumergible) y el electrodo de referencia estén completamente 
        cubiertos por la solución.`,
      },
      {
        main: "Inicio del proceso",
        points: `Al presionar el botón de ENVIAR el proceso comenzara,antes de eso considere que en su módulo de agua se observara 
        un led encender esto indica el inicio del proceso.`,
      },
      {
        main: "Movimiento alrededor de la sonda",
        points: `Considere que al iniciar el proceso debe mover la sonda en la solución durante un minuto para una mejor calibración, 
         despues solo mantener la sonda derecha y sin movimiento.`,
      },
      {
        main: "Estamos terminando el proceso",
        points: `Cuando el led en su módulo de agua se haya apagado el proceso estará terminado y la sonda calibrada con la  
        solución seleccionada.`,
      },
      {
        main: "Proceso terminado",
        points: `Su sonda esta calibrada con la solución.`,
      },
    ],
  },
  {
    tittleStep: "Solución pH7.0",
    text: " ",
    headers: [
      "Tenga en cuenta los siguientes consejos",
      "Tenga presente las siguientes indicaciones antes de comenzar",
      "Tenga paciencia el proceso puede demorar unos minutos",
      " ",
    ],
    textStep: [
      {
        main: "Solución de calibración",
        points: `La solución es crucial para obtener lecturas adecuadas por ello es importante que su solución se encuentre en el valor 
        correcto. Recomendamos adquirir soluciones recientes o verificar su valor con un medidor manual de pH.`,
      },
      {
        main: "Sonda del sensor",
        points: `Recuerde que al cambiar de solución de calibración es necesario tener limpia la sonda, cambiar de solución sin limpieza 
        previa provocara errores en las mediciones de su dispositivo.`,
      },
      {
        main: "Burbujas alrededor de la sonda",
        points: `Si observa que se forman burbujas alrededor del bulbo despues de sumergir en la solución agite levemente 
        la sonda para que desaparezcan`,
      },
      {
        main: "Listo para la calibración",
        point_1: `Llene un recipiente limpio con la `,
        point_2: "solución de calibración de pH 7.0 ",
        point_3: `y sumerja la sonda en ella. Asegúrese de que el bulbo(extremo sumergible) y el electrodo de referencia estén completamente 
        cubiertos por la solución.`,
      },
      {
        main: "Inicio del proceso",
        points: `Al presionar el botón de ENVIAR el proceso comenzara,antes de eso considere que en su módulo de agua se observara 
        un led encender esto indica el inicio del proceso.`,
      },
      {
        main: "Movimiento de la sonda",
        points: `Considere que al iniciar el proceso debe mover la sonda en la solución durante un minuto para una mejor calibración, 
        despues solo mantener la sonda derecha y sin movimiento.`,
      },
      {
        main: "Estamos terminando el proceso",
        points: `Cuando el led en su módulo de agua se haya apagado el proceso estará terminado y la sonda calibrada con la  
        solución seleccionada.`,
      },
    ],
  },
  {
    tittleStep: "Finalizar",
    text: " ",
    textStep: [
      {
        main: "Proceso terminado",
        points: `Su sonda esta completamente calibrada.`,
      },
    ],
  },
];

const infoStepperCE = [
  {
    tittleStep: "Preparación del sensor",
    text: ` `,
    textStep: [
      {
        main: "Consideraciones antes de calibrar la sonda",
        points: `Antes de calibrar la sonda de EC, asegúrese de que el instrumento esté encendido y conectado a la sonda.
         Limpie la punta de la sonda con agua destilada y séquela con un paño suave.`,
      },
      {
        main: "Soluciones de calibración de la sonda",
        points:
          "Asegúrese de contar con las soluciones de calibración para 1413 us/cm y 12.88 ms/cm",
      },
    ],
  },
  {
    tittleStep: "Calibración",
    text: ` `,
    textStep: [
      {
        main: "Acerca del proceso de calibración",
        points: `El proceso de calibración es un paso importante para el funcionamiento correcto de su dispositivo, es un 
        proceso que le tomara unos minutos pero solo se realiza una vez.`,
      },
    ],
    //labelSlider:["Encender por 'x' seg.","Encender hasta obtener 'x' mlts.","Calibrar con probeta de 'x' mlts."]
  },
  {
    tittleStep: "Reseteo de valores",
    text: "Procesando",
    textStep: [
      {
        main: "Reseteando valores",
        points: `En su módulo de agua se observara el encendido de un indicador cuando este se apague el proceso quedará terminado.`,
      },
      {
        note: "Si necesita solo resetear los valores por defecto puede dar en finalizar",
      },
    ],
  },
  {
    tittleStep: "Solución 1413 us/cm",
    text: " ",
    headers: [
      "Tenga en cuenta los siguientes consejos",
      "Tenga presente las siguientes indicaciones antes de comenzar",
      "Tenga paciencia el proceso puede demorar unos minutos",
      " ",
    ],
    textStep: [
      {
        main: "Solución de calibración",
        points: `La solución es crucial para obtener lecturas adecuadas por ello es importante que su solución se encuentre en el valor 
        correcto. Recomendamos adquirir soluciones recientes o verificar su valor con un medidor manual de EC.`,
      },
      {
        main: "Sonda del sensor",
        points: `Recuerde que al cambiar de solución de calibración es necesario tener limpia la sonda, cambiar de solución sin limpieza 
        previa provocara errores en las mediciones de su dispositivo.`,
      },
      {
        main: "Burbujas alrededor de la sonda",
        points: `Si observa que se forman burbujas alrededor del bulbo despues de sumergir en la solución agite levemente 
        la sonda para que desaparezcan.`,
      },
      {
        main: "Listo para la calibración",
        point_1: `Llene un recipiente limpio con la `,
        point_2: "solución de calibración de 1413 us/cm ",
        point_3: `y sumerja la sonda en ella. Asegúrese de que el bulbo(extremo sumergible) y el sensor de temperatura esten 
        sumergidos antes de comenzar.`,
      },
      {
        main: "Inicio del proceso",
        points: `Al presionar el botón de ENVIAR el proceso comenzara,antes de eso considere que en su módulo de agua se observara 
        un led encender esto indica el inicio del proceso.`,
      },
      {
        main: "Movimiento alrededor de la sonda",
        points: `Considere que al iniciar el proceso debe mover la sonda en la solución durante un minuto para una mejor calibración, 
         despues solo mantener la sonda derecha y sin movimiento.`,
      },
      {
        main: "Estamos terminando el proceso",
        points: `Cuando el led en su módulo de agua se haya apagado el proceso estará terminado y la sonda calibrada con la  
        solución seleccionada.`,
      },
      {
        main: "Proceso terminado",
        points: `Su sonda esta calibrada con la solución.`,
      },
    ],
  },
  {
    tittleStep: "Solución 12.88 ms/cm",
    text: " ",
    headers: [
      "Tenga en cuenta los siguientes consejos",
      "Tenga presente las siguientes indicaciones antes de comenzar",
      "Tenga paciencia el proceso puede demorar unos minutos",
      " ",
    ],
    textStep: [
      {
        main: "Solución de calibración",
        points: `La solución es crucial para obtener lecturas adecuadas por ello es importante que su solución se encuentre en el valor 
        correcto. Recomendamos adquirir soluciones recientes o verificar su valor con un medidor manual de EC.`,
      },
      {
        main: "Sonda del sensor",
        points: `Recuerde que al cambiar de solución de calibración es necesario tener limpia la sonda, cambiar de solución sin limpieza 
        previa provocara errores en las mediciones de su dispositivo.`,
      },
      {
        main: "Burbujas alrededor de la sonda",
        points: `Si observa que se forman burbujas alrededor del bulbo despues de sumergir en la solución agite levemente 
        la sonda para que desaparezcan`,
      },
      {
        main: "Listo para la calibración",
        point_1: `Llene un recipiente limpio con la `,
        point_2: "solución de calibración de 12.88 ms/cm ",
        point_3: `y sumerja la sonda en ella. Asegúrese de que el bulbo(extremo sumergible) y el sensor de temperatura esten 
        sumergidos antes de comenzar.`,
      },
      {
        main: "Inicio del proceso",
        points: `Al presionar el botón de ENVIAR el proceso comenzara,antes de eso considere que en su módulo de agua se observara 
        un led encender esto indica el inicio del proceso.`,
      },
      {
        main: "Movimiento de la sonda",
        points: `Considere que al iniciar el proceso debe mover la sonda en la solución durante un minuto para una mejor calibración, 
        despues solo mantener la sonda derecha y sin movimiento.`,
      },
      {
        main: "Estamos terminando el proceso",
        points: `Cuando el led en su módulo de agua se haya apagado el proceso estará terminado y la sonda calibrada con la  
        solución seleccionada.`,
      },
    ],
  },
  {
    tittleStep: "Finalizar",
    text: " ",
    textStep: [
      {
        main: "Proceso terminado",
        points: `Su sonda esta completamente calibrada.`,
      },
    ],
  },
];

const selecOptionsSoil = [
  { value: 6, name: "Reset Soil" },
  { value: 7, name: "Calibración Soil Min (Seco)" },
  { value: 8, name: "Calibración Soil Max (Humedad)" },
];

const infoStepperSoil = [
  {
    tittleStep: "Preparación del sensor",

    text: `Asegúrese que el sensor este completamente seco y 
        tener a la mano la solucion de calibración MAX (Agua) del sensor`,
    textButton: "Siguiente",
  },
  {
    tittleStep: "Seleccione operación a realizar",
    text: `Compruebe que la sonda este completamente seca y se encuentre conectada de manera adecuada `,
    textStep: [
      {
        main: "Regresa los valores del sensor de CE a sus valores predeterminados.",
        points: [
          "a) Presione el botón de 'Comenzar Proceso' el cual aparece debajo de este mensaje",
          "b) El led de configuración en el módulo principal cambiara a color rojo.",
          "c) Una vez que el led este apagado, el sensor estará en sus valores predeterminados",
        ],
      },
      {
        main: "NOTA: Recuerde que el proceso de calibración, para óptimas lecturas, debe ser por ambas soluciones de referencia, seco y humedo",
        points: [
          "Siga las siguientes instrucciones:",
          "a) Mantenga la sonda en una superficie seca y limpia.",
          "b) Presione el botón de 'Comenzar Proceso' el cual aparece debajo de este mensaje. El led de configuración en el módulo principal cambiara a color CIAN.",
          "c) Deje de manera estática la sonda hasta que el led cambie de color a VERDE",
          "d) Cuando el led se led haya cambiado de color a VERDE, usted ha completado la calibración minima (seco) de su sonda",
          "e) Continuar con el siguiente paso en el proceso de calibración",
        ],
      },
      {
        main: "NOTA: Recuerde que el proceso de calibración, para óptimas lecturas, debe ser por ambas soluciones de referencia, seco y humedo",
        points: [
          "Siga las siguientes instrucciones:",
          "a) Mantenga la sonda en una superficie seca y limpia.",
          "b) Inroduzca la sonda en un recipiente con agua, cuidando que la parte electrónica  no sea introducida",
          "c) Presione el botón de 'Comenzar Proceso' el cual aparece debajo de este mensaje. El led de configuración en el módulo principal cambiara a color MORADO.",
          "d) Deje de manera estática la sonda hasta que el led cambie de color a VERDE",
          "e) Cuando el led se led haya cambiado de color a VERDE, usted ha completado la calibración máxima (humedo) de su sonda",
          "f) Continuar con el siguiente paso en el proceso de calibración",
        ],
      },
    ],
    textButton: "Comenzar proceso",
    //labelSlider:["Encender por 'x' seg.","Encender hasta obtener 'x' mlts.","Calibrar con probeta de 'x' mlts."]
  },
  {
    tittleStep: "Procesando",
    text: "Proceso terminado, ha calibrado correctamente su sonda de CE, ¡Felicidades!",
    textButton: "Salir",
  },
];

const selecOptionsCE = [
  { value: 5, name: "Reset CE" },
  { value: 3, name: "Calibración 1413 μs/cm" },
  { value: 4, name: "Calibración 12.88 ms/cm" },
];



const useStyles = makeStyles((theme) => ({
  formField: {
    margin: theme.spacing(1), // Agrega espaciado uniforme entre campos
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5), // Menos espacio en pantallas pequeñas
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    // Ajusta el tamaño del input y otros estilos aquí
    width: '100%', // Hace que el input ocupe todo el ancho del contenedor
    padding: theme.spacing(1), // Usa el sistema de espaciado de Material-UI para el padding
    [theme.breakpoints.down('sm')]: {
      // Ajustes para pantallas pequeñas (dispositivos móviles)
      padding: theme.spacing(0.5),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
    minHeight: 50,
  },
}));

const defaultOpenAlert={open:null,
  type:null,
  tittle:null,
  txt:null}

const ConfigAnalogInput = (propiedades) => {
  const props = propiedades.data;
  // console.log({props, propiedades})
  
  const ide = props.ide; //es el id que se muestra en el navegador SW@x
  const classes = useStyles();

  const { usuario, userTimezone } = React.useContext(UserContext);
  const [error, setError] = React.useState(null);
  const [comp, setComp] = React.useState(null);
  const [nodeNameCache, setNodeNameCache] = React.useState();
  const [loading,setLoading]= React.useState(null)
  const [openWindow, setOpenWindow] = React.useState(false);
  const [openCalibration, setOpenCalibration] = React.useState(false);
  const [openAlert,setOpenAlert]=React.useState(defaultOpenAlert)//Para abrir la Alerta de notificaciones

  const [typeStepper, settypeStepper] = React.useState();
  const [typeOptions, settypeOptions] = React.useState();

  const [mathSign, setMathSign] = React.useState("0");
  const [offsetValue, setOffsetValue] = React.useState('');
  const [integerValue, setIntegerValue] = React.useState(0);
  const [decimalValue, setDecimalValue] = React.useState(0);
  const [integerDivision, setIntegerDivision] = React.useState(0);
  const [isOffset, setIsOffset] = React.useState(false);
  const [nameFlag, setNameFlag] = React.useState(false);
  const [offsetFlag, setOffsetFlag] = React.useState(false);
  const [tiempoRestante, setTiempoRestante] = React.useState(5); // Tiempo inicial en segundos
  const [awaitingResponseFlag, setAwaitingResponseFlag] = React.useState(false)

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const nameUid = `Config@${ide}`;
      const [mac, canid] = ide.split("@");
      const addr = `${usuario.username}/infoDevices/${mac}/${canid}/configModule`;
      setNodeNameCache(nameUid);

      try {
        // Primero verificamos si existe en el localStorage
        const cachedData = localStorage.getItem(nameUid);
        const data = await leerDesdeDB(addr, ide);
        if (data) {
          const actualItem = { id: data.id, ...data.data() };
          setComp(actualItem.item);
          setMathSign(actualItem.item.offset_sign);
          setOffsetValue(actualItem.item.offset_value);
          //setComp(data.item);
          localStorage.setItem(nameUid, JSON.stringify(data.data().item));
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    obtenerDatos();
  }, [ide, usuario.username]);

  // Función reutilizable para leer datos de Firebase
  const leerDesdeDB = async (addr, docId) => {
    try {
      const docRef = db.collection(addr).doc(docId);
      const docSnap = await docRef.get();

      if (docSnap.exists) {
        //console.log("Documento encontrado en Firebase:", docSnap.data());
        return docSnap;
      } else {
        console.warn("Documento no encontrado en Firebase.");
        return null;
      }
    } catch (error) {
      console.error("Error al leer desde Firebase:", error);
      return null;
    }
  };

  const leerDatosDB = async (addr, docId) => {
    return await leerDesdeDB(addr, docId);
  };

  React.useEffect(() => {
    switch (propiedades.typeCalibration) {
      case "CalPH":
        settypeOptions(selecOptionsPH);
        settypeStepper(infoStepperPH);
        break;
      case "CalCE":
        settypeOptions(selecOptionsCE);
        settypeStepper(infoStepperCE);
        break;
      case "CalSOIL":
        settypeOptions(selecOptionsSoil);
        settypeStepper(infoStepperSoil);
        break;

      default:
        settypeOptions(selecOptionsPH);
        settypeStepper(infoStepperPH);
        break;
    }
  }, []);

  const handleChange = (event) => {
    const valueSign = event.target.value
    setMathSign(event.target.value); 
    setComp(() => ({ ...comp,offset_sign: valueSign}));
    setIsOffset(true);
    setOffsetFlag(true);  
  };

  
  const close=(e)=>{
    //console.log(e)
    if(openAlert.type==="error"){
        setLoading(false)
    }
    setOpenAlert(defaultOpenAlert)
  }

  const handleChangeValue = (event) => {
    if(event.target.value >= 0){
      const value = event.target.value === '' ? 0 : event.target.value;
      setOffsetValue(event.target.value);
      //console.log("Esto es changeValue:",event.target.value);
      if (event.target.value === '') {
        //setOffsetValue(0.0);
        const entera = 0;
        const decimal = 0;
        setIntegerValue(entera);
        setDecimalValue(decimal);
        setOffsetFlag(true);  
        //console.log(value);
      } else{      
        const partes = value.split('.'); // Separar por el punto decimal
        const entera = parseInt(partes[0], 10);
        const decimal = partes.length > 1 ? parseInt(partes[1], 10) : 0;
        setIntegerValue(entera);
        setDecimalValue(decimal);
        setOffsetFlag(true);  
      }
      
      setComp(() => ({ ...comp,offset_value: value}));
      setIsOffset(true);
    }
  };

  const calculateIntegerDivision = () => {
    const result = Math.floor(integerValue / 256);
    setIntegerDivision(result);
  };

  const editar = async (e) => {
    e.preventDefault();

    if (!comp.name.trim()) {
      setError("Ingrese Nombre");
      return;
    }
    setError(null);
    setOpenWindow(true);
  };

  const agreeDialog = async () => {
    //Aun no guarda solo manda a cloudfunctions para mandar a IOT
    //const item={...node, fecha:Date.now(),msMqtt:codifyRules(node.arrayRules,node.canid)}
    setLoading(true)
    const changeName = async() => {
      const addr = `${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/configModule`;
      const docRef = db.collection(addr).doc(ide);
      const doc = await docRef.get();
      if (doc.exists) {
        let item = doc.data().item;

        item.name = comp.name;

        docRef.update({
          item
        })
        .then(() => {
          console.log("Documento actualizado con éxito");
        })
        .catch((error) => {
          console.error("Error al actualizar el documento: ", error);
        });
        setNameFlag(false);
      }
    }

    try {

      if(offsetFlag && nameFlag) {
        await changeName();
        await sendingCommand();
        setAwaitingResponseFlag(true);

      } else if(nameFlag){
        await changeName();
        setOpenAlert({
          open:true,
          type:"success",
          tittle:"Cambio de Nombre",
          txt:"El nombre se ha cambiado correctamente",
        })
        setAwaitingResponseFlag(true);
      } else if(offsetFlag) {
        await sendingCommand();
        setAwaitingResponseFlag(true);
      }
      
      // offsetFlag === true && await sendingCommand();
      // nameFlag === true && await changeName(); 
     
      //setFecha(Date.now());
      setOpenWindow(false);
    } catch (error) {
      console.log(error);
    }
  };  

  const sendingOperationCalibration = async (e, actionMs) => {
    //console.log(e)

    const item = {
      msMqtt: e,
      mac: comp.mac,
      action: actionMs,
      fecha: Date.now(),
      uid: nodeNameCache,
    };

    try {
      const addr = `${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`;
      await db.collection(addr).doc("sendConfig").set({ item });
    } catch (error) {
      console.log(error);
    }
  };

  const sendingCommand = () => {
    const arrayDeCadenas = ide.split("@");
    const kindSensor = arrayDeCadenas[2] === "2" ? arrayDeCadenas[3] : arrayDeCadenas[2];
    let mqtt = "";
    const len = 6;
    const action = arrayDeCadenas[2] === "2" ? 248 : 249;
    calculateIntegerDivision();
    mqtt =
        len +
        "," +
        action +
        "," +
        comp.canid +
        "," +
        kindSensor+
        "," +
        mathSign +
        "," +
        integerValue +
        "," +
        decimalValue + 
        "," +
        integerDivision;
        const messageAction = "Offset";
    sendingOperationCalibration(mqtt,messageAction);
    setOffsetFlag(false);
    //console.log("delay de 0.5 Seg.")
    //console.log(mqtt);
    // Aquí implementamos el delay de 0.5 segundos antes de permitir que la función reciba nuevos datos.
    return new Promise(resolve => setTimeout(resolve, 500));
  }

  const updateValues = () => {
    //console.log("Esto es comp.canid:",comp.canid)
    if(comp.canid !== "1") {
      let mqtt = "";
      const len = 2;
      const action = 6;
      const askData = 1;

      mqtt =
        len +
        "," +
        action +
        "," +
        comp.canid +
        "," +
        askData;
        const messageAction = "askingData2";
        //console.log("Esto es updateValues");
    sendingOperationCalibration(mqtt,messageAction);

    }
  }

  useEffect(() => {
    try {
      const arrayDeCadenas = ide.split("@");
      const mac = arrayDeCadenas[0]
      const canid = arrayDeCadenas[1]
      const addr = usuario.username+'/infoDevices/'+mac+'/'+canid+'/fromModule/configOK';
      const configModuleAddr =  usuario.username + '/infoDevices/'+mac+'/'+canid+'/configModule/'+ide;
      const unsubscribe = db.doc(addr).onSnapshot(snapshot => {
          const data = snapshot.data();
          if(data) {
              if((data.accion === 248 || data.accion === 249) && data.act === 'recOK') {
                  setLoading(false)//Una ves que llega el mensaje desactiva el bloqueo, el loading
                  setOpenAlert({
                    open:true,
                    type:"success",
                    tittle:"Configuración Exitosa",
                    txt:"El modulo se configuro correctamente",
                  })
                  const items = {
                    accion: 0,
                    act: "-"
                  }
                  
                  const item = {
                    ...comp,
                    fecha: Date.now(),
                    offset_sign: comp.offset_sign,
                    offset_value: comp.offset_value
                  }
                  
                  db.doc(configModuleAddr).update({item})
                  .then(
                    updateValues()
                  ).catch(error => {
                    console.error("Error al actualizar los datos de offset:", error);
                  });
                  
                  db.doc(addr).update(items)
                  .then(() => {
                      //console.log("OK");
                      setOpenAlert({
                          open:true,
                          type:"success",
                          tittle:"Configuración Exitosa",
                          txt:"El modulo se configuro correctamente",
                      })
                  })
                  .catch(error => {
                      console.error("Error al actualizar recOk:", error);
                  });
              }
          }
      })
      // Limpiar el listener al desmontar el componente
      return () => unsubscribe();
    } catch (error) {
      
    }
  }, [ide,usuario.username,comp])

  useEffect(() => {
    if (tiempoRestante > 0 && awaitingResponseFlag) {
      const temporizador = setTimeout(() => {
        setTiempoRestante(tiempoRestante - 1);
      }, 1000); // Reducir un segundo cada vez
      return () => clearTimeout(temporizador); // Limpiar el temporizador en cada ciclo
    } else {
      setOpenAlert(defaultOpenAlert)
      setAwaitingResponseFlag(false)
      setTiempoRestante(5)
    }
  }, [tiempoRestante,awaitingResponseFlag]);
  

 /* Notifica si Hubo un error o el mensaje cuando se envio al IoT*/ 
 useEffect(()=>{
  const ChangeFireStore= async() => {
      const add=usuario.username+'/logs/'+comp.mac;
      
      await db.collection(add).where('act', '==', 'sendConfigModule')
      .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
              
          if (change.type === 'modified') {
              //console.log()
              const data=change.doc.data()
              //if(data.action!==actionLink){return} 
              if(data.action !== "Offset" && data.action !== "Advanced Config"){return}
              if(data.uid!==nodeNameCache){return}//si este uid no fue el que se actualizo no cambia parametros
              let alertjs={}
              switch(data.state){
                  case "Error":
                      alertjs={
                          open:true,
                          type:"error",
                          tittle:"Error",
                          txt:"Hubo un error al enviar: Verifique la conexión a internet de GAPY-Master",
                      }
                      break;
                  case "Info":
                      alertjs={
                          open:true,
                          type:"info",
                          tittle:"Info",
                          txt:"Espere a que se configure correctamente GAPY-MASTER",
                      }
                      break;
                  default:
                      alertjs=defaultOpenAlert;
                      break;
              }
              setOpenAlert(alertjs)
          }
          
          });
      });
  }
  
  if(comp && usuario.username){
      ChangeFireStore();
  }
},[comp,usuario.username,setOpenAlert,nodeNameCache])


  return (
    comp && (
      <div className="container mt-3">
        <form onSubmit={editar}>
          <div className="row">
            <div className="col-12">
              <ul className="list-group">
                <li
                  className="list-group-item"
                  style={{ background: backGroundList }}
                >
                  <div className="row">
                    <div className=" col-10">
                      <h4 style={{ color: "white" }}>{propiedades.head}</h4>
                    </div>

                    {propiedades.cal === true && (
                      <div className="col-2">
                        <Tooltip
                          title={
                            <h5 style={{ color: "white" }}>Calibrar Sensor</h5>
                          }
                          arrow
                        >
                          <IconButton
                            color="inherit"
                            aria-label="menu"
                            style={{ color: "white" }}
                            onClick={() => {
                              setOpenCalibration(true);
                            }}
                          >
                            <Adjust
                              fontSize="large"
                              style={{ color: "white" }}
                            />
                          </IconButton>
                        </Tooltip>

                        <CalibrationDialogForm
                          handleClose={() => {
                            setOpenCalibration(false);
                          }}
                          open={openCalibration}
                          dialogTittle="Calibración de sensor"
                          dialogText="Para calibrar el sensor, favor de seguir los siguientes pasos:"
                          infoStepper={typeStepper}
                          sendingOperationCalibration={
                            sendingOperationCalibration
                          }
                          canid={comp.canid}
                          outid={propiedades.outid}
                          selecOptions={typeOptions}
                          typeCalibration={propiedades.typeCalibration}
                        />
                      </div>
                    )}
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">MAC</div>
                    <div className="col-6">{comp.mac} </div>
                  </div>

                  <div className="row">
                    <div className="col-6">CAN:</div>
                    <div className="col-6">{comp.canid}</div>
                  </div>

                  <div className="row">
                    <div className="col-6">Name:</div>
                    <div className="col-6">
                      <input
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control mb-2"
                        onChange={(e) => {
                          setComp({ ...comp, name: e.target.value })
                          setNameFlag(true);
                          }
                        }
                        value={comp.name}
                      ></input>
                    </div>
                  </div>

                  {comp.fecha && (
                    <div className="row">
                      <div className="col-6">Last Update:</div>
                      <div className="col-6">
                        {/* {moment(comp.fecha).format("llll")} */}
                        {/* {new Date(comp.fecha).toString()} */}
                        {moment(comp.fecha).tz(userTimezone).format('ddd, D [de] MMM [de] YYYY, HH:mm [Hrs.]')}
                      </div>
                    </div>
                  )}

                  <div style={{ marginTop: '15px' }}>
                    <Divider className={classes.divider} />
                  </div>

                  <div style={{marginBottom: '15px'}}>
                    <Grid container
                    justifyContent="center"
                    alignItems="center" >
                      <Typography variant="h6" className={classes.title}>
                        Offset
                      </Typography>
                    </Grid>
                  </div>

                  <div className="row" style={{marginBottom: '40px'}}>
                    <div className="col-4">Offset value:</div>
                    <div className="col-6">
                      <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <TextField 
                          id="selectSign" 
                          label="+ / -" 
                          value={mathSign} 
                          select 
                          onChange={handleChange} 
                          size="small"
                          variant="outlined">
                            <MenuItem value={"0"}>+</MenuItem>
                            <MenuItem value={"1"}>-</MenuItem>
                          </TextField>
                        
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          
                        <TextField
                          id="outlined-number"
                          label="Valor"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={offsetValue}
                          onChange={handleChangeValue}
                          size="small"
                          variant="outlined"
                          inputProps={{
                            step: 0.1, // Permite incrementar en 0.1
                            min: "0", // Valor mínimo que puedes definir según tus necesidades
                            // max: "10", // Valor máximo, opcional según tus necesidades
                          }}
                        />
                      
                        </Grid>

                      </Grid>
                    
                    </div>
                  </div>

                  {/* <div style={{marginBottom: 'px'}}></div> */}

                </li>
              </ul>
            </div>
          </div>

          <div className="row ">
            {
              //como operador ternario
              error && (
                <div className="col-12 mt-3">
                  <div className="alert alert-danger alert-block">{error}</div>
                </div>
              )
            }
          </div>

          <UpdatingButton type="submit" disabled={false} />
        </form>

        <DialogConfirm
          open={openWindow}
          handleClose={() => setOpenWindow(false)}
          agreeDialog={agreeDialog}
          title={"¿Está seguro que quiere continuar con esta operación?"}
          text={
            "Permite realizar configuraciones de la salidas o entradas del Nodo. Verifique que el Nodo que desea configurar este conectado através del GAPY-BUS"
          }
        />

        <TransitionAlerts
          data={openAlert}
          onClick={close}
        />
      </div>
    )
  );
};

export default ConfigAnalogInput;
