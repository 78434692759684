import React from 'react'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "90%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
}));

export const ConductivityUnits = ({selectedUnit, newUnitsForEC, ecChangeUnitsFlag}) => {
	const classes = useStyles();
	const allUnits = [
		{ label: "dS/cm", value: 100000 },
		{ label: "mS/cm", value: 1000 },
		{ label: "uS/cm", value: 1 },
	]

	const handleChangeEcUnit = (event) => {
		newUnitsForEC(event.target.value)
		ecChangeUnitsFlag();
	}

  return (
	<div>
		<label className={"mt-3"}>Conductividad Electrica(EC):</label>
		<FormControl variant="outlined" className={classes.formControl} size='small'>
			{/* <InputLabel id="timeZone-title">Zona Horaria actual</InputLabel> */}
			<Select
			labelId="Conductivity-Units"
			id="conductivity-units"
			value={selectedUnit}
			onChange={handleChangeEcUnit}
			// label="Zona Horaria actual"
			labelWidth={0}
			>
				{allUnits.map((unit) => (
					<MenuItem key={unit.value} value={unit.value}>{unit.label}</MenuItem>
				))}
			</Select>
		</FormControl>
	</div>
  )
}
