import React from 'react'
import { TransitionAlerts } from '../../../Common'
import { db } from "../../../../config/firebase";
import { UserContext } from "../../../../context/UserProvider";
import {
    ACTION_MQTT_WHEN_TOGGLE,
    ACTION_MQTT_WHEN_ASK,
  } from "../Functions";
  import {
    defaultOpenAlert,
  } from "../constants"


const alerts={
    isDeviceDisconnectedWhenToggle:{
      open: true,
      type: "error",
      tittle: "Error",
      txt: "El dispositivo se encuentra desconectado",
    },
    isSendingCommand:{
      open: true,
      type: "info",
      tittle: "Info",
      txt: "Enviando comando",
    },
    isDeviceDisconnectedWhenRequestDataNode:{
      open: true,
      type: "error",
      tittle: "Error",
      txt: "El dispositivo se encuentra desconectado",
    },
    isRequestDataNodeSucces:defaultOpenAlert,
    isCommandToggleReceivedSucces:{
      open: true,
      type: "success",
      tittle: "Configuración Exitosa",
      txt: "El modulo se configuro correctamente",
    }
  }
  
  const getAlertObject=(data)=>{
  
  
    const {state:stateRequestDataNode,action:actionDescription}=data
    
  
    const isActionWhenToggle= actionDescription === ACTION_MQTT_WHEN_TOGGLE
    const isActionWhenRequestNode=actionDescription === ACTION_MQTT_WHEN_ASK
    const isStateRequestDataNodeError=stateRequestDataNode=== "Error"
    const isStateRequestDataNodeInfo=stateRequestDataNode=== "Info"
    const isStateWhenToggleSucces=stateRequestDataNode=== "Succes"
    
  
    const isDeviceDisconnectedWhenToggle = isStateRequestDataNodeError && isActionWhenToggle 
    const isSendingCommand=isStateRequestDataNodeInfo &&  isActionWhenToggle;
    const isDeviceDisconnectedWhenRequestDataNode = isStateRequestDataNodeError && isActionWhenRequestNode;
    const isRequestDataNodeSucces=isStateRequestDataNodeInfo && isActionWhenRequestNode;
    const isCommandToggleReceivedSucces=isActionWhenToggle && isStateWhenToggleSucces;
  
    if(isCommandToggleReceivedSucces){
      return alerts.isCommandToggleReceivedSucces
    }
     
    if (isDeviceDisconnectedWhenToggle) {
      return alerts.isDeviceDisconnectedWhenToggle
    } 
  
    if (isSendingCommand) {
      return alerts.isSendingCommand;
    } 
  
    if (isDeviceDisconnectedWhenRequestDataNode) {
      return alerts.isDeviceDisconnectedWhenRequestDataNode
    } 
    
    if (isRequestDataNodeSucces) {
      return alerts.isRequestDataNodeSucces
    } 
  
    return defaultOpenAlert
  }

  //TODO: [GA-707]Crear link con ConfigDate,ConfigDigitalInput,ConfigNode,ConfigOutAc,ConfigPumbDC
const TransitionAlertCommand = () => {
    const { usuario,dataMacCan } = React.useContext(UserContext);
    const { username } = usuario;
    const [openAlert, setOpenAlert] = React.useState(defaultOpenAlert);
    const [tiempoRestante, setTiempoRestante] = React.useState(5); // Tiempo inicial en segundos
    const [awaitingResponseFlag, setAwaitingResponseFlag] = React.useState(false)

    const close = () => {
        setOpenAlert(defaultOpenAlert);
      };
    
      React.useEffect(() => {
    
        const onQuerySnapshotAlert=querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            if (change.type === "modified") {  
              close();       
              const data = change.doc.data();
              const alertObj=getAlertObject(data); 
              setOpenAlert(alertObj); 
              setAwaitingResponseFlag(true)
            }
          });
        }
    
    
        const updateAlert = async () => {
          await Promise.all(
            dataMacCan.map(async (itemMac) => {
              const add = username + "/logs/" + itemMac.mac;
              await db
                .collection(add)
                .where("act", "==", "sendConfigModule")
                .onSnapshot(onQuerySnapshotAlert);
            })
          );
        };
    
        updateAlert()
        
      }, [username, dataMacCan, setOpenAlert]);

    React.useEffect(() => {
      if (tiempoRestante > 0 && awaitingResponseFlag) {
        const temporizador = setTimeout(() => {
          setTiempoRestante(tiempoRestante - 1);
        }, 1000); // Reducir un segundo cada vez
        return () => clearTimeout(temporizador); // Limpiar el temporizador en cada ciclo
      } else {
        setOpenAlert(defaultOpenAlert)
        setTiempoRestante(5)
        setAwaitingResponseFlag(false)
      } 
    
    }, [tiempoRestante,awaitingResponseFlag])
    

    return (
        <TransitionAlerts data={openAlert} onClick={close} enableHead />
    )
}

export default TransitionAlertCommand
